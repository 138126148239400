<template>
  <span>
    <v-dialog v-model="dialogRejectSefDocument" @keydown.esc="onCancel" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" circle class="mr-2" color="red">
            mdi-close
          </v-icon>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Da li želite da odbijete ulazni dokument sa SEF-a?
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            v-model="rejectionReason"
            label="Razlog odbijanja"
            clearable
            class="mt-5"
            outlined
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn>Otkaži</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="rejectSefDocument" :loading="postingToApi">
            <v-icon>mdi-close</v-icon>
            Odbij dokument
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>
  </span>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import Snackbar from './Snackbar.vue';

export default {
  name: "RejectSefDocumentDialog",
  components: {
    Snackbar
  },
  data: function () {
    return {
      dialogRejectSefDocument: false,
      postingToApi: false,
      rejectionReason: null,
      rules: {
        required: value => !!value || 'Obavezno polje.'
      },
      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCancel () {
      this.dialogRejectSefDocument = false;
    },
    rejectSefDocument() {
      if (this.rejectionReason === null || this.rejectionReason.length === 0) {
        return;
      }

      this.postingToApi = true;

      documentsAPI.rejectSefDocument(this.document.id, this.rejectionReason, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          this.postingToApi = false;
          this.showNotification("Uspešno odbijen dokument na SEF-u");
        })
        .catch(() => {
          this.postingToApi = false;
          this.showError("Greška prilikom odbijanja dokumenta na SEF-u");
        })
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
  }
}
</script>
