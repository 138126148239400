<template>
  <v-navigation-drawer app flat right dark permanent clipped :mini-variant.sync="closeDrawer" v-if="this.shouldShow" width="300px">
    <v-row class="mt-2 mb-2" justify="center">
      <v-col cols="12" sm="4" class="pa-0 ma-0">
        <span class="white--text">
        {{ this.closeDrawer ? "" : "Filteri"}}
        </span>
        <v-icon circle>
          mdi-filter-outline
        </v-icon>
      </v-col>
    </v-row>

    <v-row v-if="!closeDrawer" justify="center">
      <v-col cols="12" sm="10">
        <v-text-field
          v-model="advancedSearch.documentNumber"
          append-icon="mdi-magnify"
          label="Broj dokumenta"
          single-line
          hide-details
          clearable
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-menu v-model="menuActualDeliveryDateFrom" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" nudge-left="100px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              single-line
              clearable
              class="mt-0 pt-0"
              label="Datum prometa (od)"
              prepend-icon="mdi-calendar"
              :value="formatDate(advancedSearch.actualDeliveryDateFrom)"
              v-bind="attrs"
              v-on="on"
              @click:clear="advancedSearch.actualDeliveryDateFrom = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="advancedSearch.actualDeliveryDateFrom"
            @input="menuActualDeliveryDateFrom = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="10">
        <v-menu v-model="menuActualDeliveryDateTo" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" nudge-left="100px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              single-line
              clearable
              class="mt-0 pt-0"
              label="Datum prometa (do)"
              prepend-icon="mdi-calendar"
              :value="formatDate(advancedSearch.actualDeliveryDateTo)"
              v-bind="attrs"
              v-on="on"
              @click:clear="advancedSearch.actualDeliveryDateTo = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="advancedSearch.actualDeliveryDateTo"
            @input="menuActualDeliveryDateTo = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="10">
        <v-select
        :items="documentTypesValues"
        v-model="advancedSearch.documentType"
        item-text="text"
        item-value="value"
        label="Tip dokumenta"
        outlined dense hide-details clearable></v-select>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field
          v-model="advancedSearch.partnerCompany"
          append-icon="mdi-magnify"
          label="Kupac"
          single-line
          hide-details
          clearable
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-select
        :items="sentToSefValues"
        v-model="advancedSearch.isSentToSef"
        item-text="text"
        item-value="value"
        label="Poslato na SEF?"
        outlined dense hide-details clearable></v-select>
      </v-col>

      <v-col cols="12" sm="10">
        <v-select
        :items="sefStatusValues"
        v-model="advancedSearch.sefStatusMessage"
        item-text="text"
        item-value="value"
        label="SEF Status"
        outlined dense hide-details clearable></v-select>
      </v-col>

       <v-col cols="12" sm="10">
        <v-menu v-model="menuCreatedAt" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" nudge-left="100px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              single-line
              clearable
              class="mt-0 pt-0"
              label="Datum prijema"
              prepend-icon="mdi-calendar"
              :value="formatDate(advancedSearch.createdAt)"
              v-bind="attrs"
              v-on="on"
              @click:clear="advancedSearch.createdAt = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="advancedSearch.createdAt"
            @input="menuCreatedAt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="10">
        <v-select
        :items="errorMessageTypes"
        v-model="advancedSearch.errorMessageSearch"
        item-text="text"
        item-value="value"
        label="Tip greške"
        outlined dense hide-details clearable></v-select>
      </v-col>

      <v-col cols="12" sm="10">
        <v-btn class="primary" @click="performAdvancedSearch">
          Filtriraj
        </v-btn>
      </v-col>
    </v-row>
    <template v-slot:append>
      <v-btn icon @click.stop="closeDrawer = !closeDrawer">
        <v-icon v-if="!closeDrawer">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DrawerAdvancedSearchSalesByDistributor',
  data: function () {
    return {
      advancedSearch: {
        documentType: null,
        isSentToSef: null,
        sefStatus: null,
        documentNumber: null,
        partnerCompany: null,
        actualDeliveryDateFrom: null,
        actualDeliveryDateTo: null,
        createdAt: null,
        errorMessageSearch: null,
      },

      menuActualDeliveryDateFrom: false,
      menuActualDeliveryDateTo: false,
      menuCreatedAt: false,
      closeDrawer: true,
      shouldShow: false,

      documentTypesValues: [
        { id: "380", text: "Faktura", value: "380" },
        { id: "381", text: "Knjižno odobrenje", value: "381" },
        { id: "383", text: "Knjižno zaduženje", value: "383" },
        { id: "386", text: "Avansna faktura", value: "386" },
      ],

      sentToSefValues: [
        { id: true, text: "Da", value: true },
        { id: false, text: "Ne", value: false },
      ],

      sefStatusValues: [
        { id: "Sent", text: "Sent", value: "Sent" },
        { id: "Approved", text: "Approved", value: "Approved" },
        { id: "Rejected", text: "Rejected", value: "Rejected" },
        { id: "Storno", text: "Storno", value: "Storno" },
        { id: "Cancelled", text: "Cancelled", value: "Cancelled" },
        { id: "New", text: "New", value: "New" },
        { id: "Sending", text: "Sending", value: "Sending" },
        { id: "Mistake", text: "Mistake", value: "Mistake" },
      ],

      errorMessageTypes: [
        { id: "Source invoice with source invoice number", text: "Izvorna faktura mora biti odobrena", value: "Source invoice with source invoice number" },
        { id: "Company with identifier", text: "Kompanija nije registrovana na SEFu", value: "Company with identifier" },
        { id: "Source invoice receiver is different from document receiver", text: "Primalac izvorne fakture nije isti kao primalac ovog dokumenta", value: "Source invoice receiver is different from document receiver" },
      ],
    }
  },
  watch:{
    $route (to) {
      if (to.path === "/") {
        this.shouldShow = true;
        return;
      }

      this.shouldShow = false;
    }
  },
  mounted() {
    if (this.$router.currentRoute.path === "/") {
      this.shouldShow = true;
    }
    else {
      this.shouldShow = false;
    }
  },
  methods: {
    performAdvancedSearch () {
      let copy =JSON.parse(JSON.stringify(this.advancedSearch));
      this.$emit("filtersChanged", copy);
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },
  }
}
</script>

<style scoped>
.navmenu-item{
  margin-left: 15px;
}
</style>

