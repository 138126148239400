import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SalesPage from '../views/SalesPage.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SalesPage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/sales',
    name: 'SalesPage',
    component: SalesPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isauth = store.getters.isLoggedIn
  if (to.name !== 'Login' && isauth === false) {
    if (from.name === 'Login') {
      next(false)
    }
    next({ path: '/login' })
  }
  else {
    next()
  }
})

export default router
