<template>
  <v-app>
    <Toolbar />

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar'
export default {
  name: 'App',

  components: {
    Toolbar,
  }
};
</script>
