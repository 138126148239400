<template>
  <div>
    <v-dialog v-model="dialogStatusHistory" @keydown.esc="onCancel" persistent width="300px">
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn >
          <v-icon>mdi-history</v-icon>
        </v-btn> -->
        <v-icon v-bind="attrs" v-on="on" circle class="mr-2">
          mdi-history
        </v-icon>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Istorija statusa
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-list dense v-if="documentStatusHistory !== null && documentStatusHistory.length > 0 && gettingFromApi === false" >
                  <v-list-item v-for="documentStatusEntry in documentStatusHistory" :key="documentStatusEntry.id">
                    <v-list-item-content>
                      <v-list-item-title>{{ documentStatusEntry.statusMsg }}</v-list-item-title>
                      <v-list-item-subtitle>{{ formatDate(documentStatusEntry.createdAt) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div v-else-if="gettingFromApi === false">
                  Nema istorije statusa za izabrani dokument.
                </div>
                <v-progress-circular v-else-if="gettingFromApi === true" indeterminate>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import moment from "moment";

export default {
  name: "StatusHistoryDialog",
  data: function () {
    return {
      dialogStatusHistory: false,
      gettingFromApi: false,
      snackbar: false,
      snackbarText: "",
      documentStatusHistory: []
    }
  },
  props: {
    documentId: {
      type: Number,
      required: true
    }
  },
  watch: {
    dialogStatusHistory: {
      handler () {
        this.getStatusHistory();
      },
      deep: true,
    },
  },
  methods: {
    onCancel () {
      this.dialogStatusHistory = false;
    },
    getStatusHistory() {
      this.gettingFromApi = true;
      documentsAPI.getStatusHistory(this.documentId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.gettingFromApi = false;
          this.documentStatusHistory = responseData;
        })
        .catch(() => {
          this.documentStatusHistory = [];
          this.gettingFromApi = false;
        })
    },
    formatDate(date){
      return date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : '';
    }
  }
}
</script>
