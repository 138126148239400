import Vue from 'vue'
import Vuex from 'vuex'
import Utils from '../common/utils.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user-token'),
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    token (state) {
      return state.token
    },
    taxId(state) {
      let jsonBody = Utils.parseJwt(state.token);
      return jsonBody['pib'];
    }
  },
  mutations: {
    token(state, value) {
      state.token = value
      if (value === null) {
        localStorage.removeItem('user-token')
      }
      else {
        localStorage.setItem('user-token', value)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
