<template>
  <span>
    <v-dialog v-model="dialogApproveSefDocument" @keydown.esc="onCancel" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" circle class="mr-2" color="green">
            mdi-check-bold
          </v-icon>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Da li želite da prihvatite ulazni dokument sa SEF-a?
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-actions>
          <v-btn color="error">Ne</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="approveSefDocument" :loading="postingToApi">Prihvati</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>
  </span>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import Snackbar from './Snackbar.vue';

export default {
  components: { Snackbar },
  name: "ApproveSefDocumentDialog",
  data: function () {
    return {
      dialogApproveSefDocument: false,
      postingToApi: false,
      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCancel () {
      this.dialogApproveSefDocument = false;
    },
    approveSefDocument() {
      this.postingToApi = true;

       documentsAPI.approveSefDocument(this.document.id, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          this.postingToApi = false;
          this.showNotification("Uspešno potvrđen dokument na SEF-u");
        })
        .catch(() => {
          this.postingToApi = false;
          this.showError("Greškom prilikom potvrđivanja dokumenta na SEF-u");
        })
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

  }
}
</script>
