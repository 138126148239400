var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","rounded":false}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfSales,"server-items-length":_vm.totalCount,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%","elevation":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.isSent",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":_vm.getSentToSefColor(item.isSent),"size":"15"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getSentToSefTooltip(item.isSent)))])])]}},{key:"item.partnerCompany",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.partnerCompany)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.partnerCompany))])])]}},{key:"item.sefComment",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.sefComment)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.sefComment))])])]}},{key:"item.actualDeliveryDate",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.actualDeliveryDate)))])]}},{key:"item.issueDate",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.issueDate)))])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.statusHistory",fn:function(ref){
    var item = ref.item;
return [_c('status-history-dialog',{attrs:{"documentId":item.id}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.showSendToAmaps())?_c('send-to-amaps-dialog',{attrs:{"documentId":item.id}}):_vm._e(),(_vm.showSendToFtp())?_c('send-to-ftp-dialog',{attrs:{"purchaseInvoiceId":item.sefId}}):_vm._e(),(_vm.showSendToEmail())?_c('send-to-email-dialog',{attrs:{"documentId":item.id}}):_vm._e(),_c('approve-sef-document-dialog',{attrs:{"document":item}}),_c('reject-sef-document-dialog',{attrs:{"document":item}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.downloadingExcel},on:{"click":_vm.downloadExcelFile}},[_vm._v(" Preuzmi "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-microsoft-excel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }