<template>
  <span>
    <v-dialog v-model="dialogSendToEmail" @keydown.esc="onCancel" persistent width="500px">
      <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" circle class="mr-2">
            mdi-email-arrow-right-outline
          </v-icon>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Da li želite da pošaljete dokument na E-mail?
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-actions>
          <v-btn color="error">Ne</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="sendToEmail" :loading="postingToApi">Pošalji</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>
  </span>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import Snackbar from './Snackbar.vue';

export default {
  name: "SendToEmailDialog",
  components: {
    Snackbar
  },
  data: function () {
    return {
      dialogSendToEmail: false,
      postingToApi: false,
      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    documentId: {
      type: Number,
      required: true
    }
  },
  methods: {
    onCancel () {
      this.dialogSendToEmail = false;
    },
    sendToEmail() {
      this.postingToApi = true;
      documentsAPI.sendToEmail(this.documentId, "101987198", this.$store.getters.token)
        .then(response => response)
        .then(() => {
          this.postingToApi = false;
          this.showNotification("Dokument je uspešno poslat na email");
        })
        .catch(() => {
          this.postingToApi = false;
          this.showError("Greška prilikom slanja dokumenta na email");
        })
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
  }
}
</script>
