<template>
  <v-snackbar v-model="showSnackbar" :timeout="timeout" :color="getColor()">
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn text icon v-bind="attrs" @click="closeSnackbar()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
// TODO: premestiti ovo u zaseban fajl
let errorCodeToText = {
  "company_undefined" : "Nije definisana kompanija čiji se šifarnik dodaje",
  "file_settings_undefined" : "Nisu definisana podešavanja fajla",
  "column_settings_undefined" : "Nisu definisana podešavanja kolona",
  "file_name_undefined" : "Nije definisan naziv fajla",
  "naziv_column_undefined" : "Nije definisana kolona u kojoj se nalazi naziv artikla",
  "sifra_column_undefined" : "Nije definisana kolona u kojoj se nalazi šifra artikla",
  "validation_failed" : "Greška u validaciji...",
  "file_undefined" : "Greška u Excel fajlu",
  "naziv_undefined" : "Fali naziv za neku od stavki",
  "sifra_undefined" : "Fali šifra za neku od stavki",
  "file_read_error" : "Greška prilikom čitanja fajla",
  "codebook_undefined" : "Nije definisan šifarnik",
  "sender_settings_undefined" : "Nisu definisana podešavanja pošiljaoca",
  "receiver_settings_undefined" : "Nisu definisana podešavanja primaoca",
  "invalid_documentType" : "Nevalidan tip dokumenta",
  "invalid_channelID" : "Nevalidan kanal prenosa",
  "invalid_outputformat" : "Nevalidan izlazni format",
  "invalid_softwareID" : "Nevalidan software",
  "invalid_receiverID" : "Nevalidan primalac",
  "invalid_senderID" : "Nevalidan pošiljalac",
  "invalid_receiverCompanyID" : "Nevalidna kompanija primalac",
  "invalid_senderCompanyID" : "Nevalidan kompanija pošiljalac",
  "invalid_priority" : "Nevalidan prioritet",
  "notfound_channelID" : "Kanal prenosa nije nadjen u bazi podataka",
  "notfound_outputformat" : "Izlazni format nije nadjen u bazi podataka",
  "notfound_software" : "Software nije nadjen u bazi podataka",
  "notfound_sender" : "Pošiljalac nije nadjen u bazi podataka",
  "notfound_receiver" : "Primalac nije nadjen u bazi podataka",
  "notfound_receiverCompany" : "Kompanija primalac nije nadjena u bazi podataka",
  "notfound_senderCompany" : "Kompanija pošiljalac nije nadjena u bazi podataka",
  "notfound_dispatcherRule" : "Pravilo/izuzetak nije nadjen u bazi podataka",
  "invalid_params_to_create_exception" : "Nevalidni parametri za kreiranje izuzetka",
  "only_softwareID_to_create_rule" : "Za kreiranje pravila potrebno je izabrati samo Software",
  "invalid_combination_mix" : "Ne možete kombinovati isporučna mesta i kompanije",
  "invalid_combination_RID_null" : "Morate izabrati i isporučno mesto primaoca",
  "invalid_combination_recCompany_null" : "Morate izabrati i kompaniju primaoca",
  "invalid_combination_software_and_ids" : "Software se ne može kombinovati sa ID-evima",
  "invalid_dispatcherRuleID" : "Nevalidan ID pravila/izuzetka",
  "priority_or_ids" : "Dozvoljeno je filtriranje ili po prioritetu ili po ID-evima",
  "ftpClientOptions_alreadyExists" : "FTP opcije sa zadatim skupom ID-eva već postoje u bazi",
  "client_customer_not_belong_to_company" : "Izabrano klijentsko isporučno mesto ne pripada izabranoj kompaniji",
  "invalid_AlternativeReceiver" : "Nevalidan alternativni primalac",
  "invalid_customerAltReceiver_exists" : "Ova veza već postoji"
};

export default {
  name: "Snackbar",
  data: function () {
    return {
      snackbarText: ""
    }
  },
  props: {
    timeout: {
      type: Number,
      default: -1,
      required: false
    },
    showSnackbar: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isError: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    showSnackbar: {
      handler() {
        this.showError();
      },
      deep: true
    },
    text: {
      handler () {
        this.showError();
      },
      deep: true,
    }
  },
  methods: {
    getColor() {
      if (this.isError)
        return "error";
      else
        return "success";
    },
    showError() {
      if (!this.isError) {
          this.snackbarText = this.text;
          return;
        }

        // jeste error
        let errorText = errorCodeToText[this.text];
        if (errorText)
          this.snackbarText = errorText;
        else
          this.snackbarText = "Nepoznata greška... Kod: " + this.text;
    },
    closeSnackbar() {
      this.$emit('update:showSnackbar', false);
    }
  }
}
</script>
