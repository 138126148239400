<template>
  <div class="mt-3 ml-5 mr-5">
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <v-tabs
          v-model="currentTab"
          align-with-title
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tabName in tabNames"
            :key="tabName"
          >
            {{ tabName }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <v-tab-item key="Izlazni">
            <v-card>
              <v-card-text class="ma-0 pa-0">
            <ListOutgoingDocuments
              :filters="outgoingFilters"
            />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="Ulazni">
             <v-card>
              <v-card-text class="ma-0 pa-0">
            <ListIncomingDocuments
              :filters="incomingFilters"
            />
              </v-card-text>
             </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>

        <DrawerAdvancedSearchSalesByDistributor
          v-if="this.$store.getters.isLoggedIn === true"
          @filtersChanged="changeDistributorSalesFilters"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';

import ListOutgoingDocuments from '../components/ListOutgoingDocuments.vue';
import ListIncomingDocuments from '../components/ListIncomingDocuments.vue';
import DrawerAdvancedSearchSalesByDistributor from '../components/DrawerAdvancedSearchSalesByDistributor.vue';

export default {
  name: "SalesPage",
  components: {
    ListOutgoingDocuments,
    ListIncomingDocuments,
    DrawerAdvancedSearchSalesByDistributor,
  },
  data() {
    return {
      currentTab: 0,
      tabNames: [
        'Izlazni', 'Ulazni'
      ],
      distributor: null,
      product: null,

      datePicker: {
        menuDateFrom: false,
        menuDateTo: false,
      },

      outgoingFilters: {
        documentType: null,
        isSent: null,
        sefStatus: null,
        documentNumber: null,
        partnerCompany: null,
        actualDeliveryDate: null
      },

      incomingFilters: {
        documentType: null,
        isSent: null,
        sefStatus: null,
        documentNumber: null,
        partnerCompany: null,
        actualDeliveryDate: null
      }
    }
  },
  methods: {
    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },

    changeDistributorSalesFilters (advancedSearch) {
      if (this.currentTab === 0) {
        this.outgoingFilters =  advancedSearch;
      } else if (this.currentTab === 1) {
        this.incomingFilters =  advancedSearch;
      }
    },
  }
}
</script>
