import * as fetch from './fetch'

export function getOutgoingDocuments(options, filters, token) {
  let parameters = mapToParameters(options);
  let body = mapToBody(filters);

  const endpoint = '/document/list/report/outgoing';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.POST(endpoint + '?' + queryString, token, body, true)
}

export function getIncomingDocuments(options, filters, token) {
  let parameters = mapToParameters(options);
  let body = mapToBody(filters);

  const endpoint = '/document/list/report/incoming';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.POST(endpoint + '?' + queryString, token, body, true)
}

export function approveSefDocument(documentId, token) {
  const endpoint = `/document/accept`;
  let body = {
    id: documentId,
    comment: null
  };

  return fetch.POST(endpoint, token, body, true)
}

export function rejectSefDocument(documentId, comment, token) {
  const endpoint = `/document/reject`;
  let body = {
    id: documentId,
    comment: comment
  };

  return fetch.POST(endpoint, token, body, true)
}

export function sendToAmaps(documentId, token) {
  const endpoint = `/document/sendToAmaps/${documentId}`;
  return fetch.POST(endpoint, token, null, true)
}

export function sendToFtp(purchaseInvoiceId, token) {
  const endpoint = `/document/sendToFtp/${purchaseInvoiceId}`;
  return fetch.POST(endpoint, token, null, true)
}

export function sendToEmail(documentId, sendToTid, token) {
  const endpoint = `/document/sendToEmail/${documentId}?sendToTid=${sendToTid}`;
  return fetch.POST(endpoint, token, null, true)
}

export function getStatusHistory(documentId, token) {
  const endpoint = `/document/statusHistory/${documentId}`;
  return fetch.GET(endpoint, token, true);
}

export function mapToBody(filters) {
  let parameters = {};
  parameters.isSentToSef = filters.isSentToSef;
  parameters.sefStatusMessage = filters.sefStatusMessage;
  parameters.documentType = filters.documentType;
  parameters.documentNumber = filters.documentNumber;
  parameters.partnerCompany = filters.partnerCompany;
  parameters.actualDeliveryDateFrom = filters.actualDeliveryDateFrom;
  parameters.actualDeliveryDateTo = filters.actualDeliveryDateTo;
  parameters.createdAt = filters.createdAt;
  parameters.errorMessageSearch = filters.errorMessageSearch;
  return parameters;
}

export function mapToParameters(options) {
  let parameters = {};

  if (options.page !== null) {
    parameters.page = options.page - 1;
  }

  if (options.itemsPerPage !== null) {
    parameters.size = options.itemsPerPage;
  }

  return parameters
}
